import { v4 as uuid } from "uuid";
import moment from "moment-timezone";
import DOMPurify from "dompurify";
import { AppFeatures, RepairStatus } from "./constants";

const scheduledStatus = [
  "RESERVED",
  "SCHEDULED",
  "ONSITE VISIT SCHEDULED",
  "READY",
  "NEW",
  "BORD",
  RepairStatus.PACKAGING_SHIPMENT,
  'PENDING SCHEDULE CONFIRMATION',
];
const inProgressStatus = [
  "CCX STARTED",
  "CCX COMPLETED",
  "CCX FAILED",
  "RCX STARTED",
  "RCX FAILED",
  "RCX CANCELLED",
  "DCX STARTED",
  "DCX FAILED",
  "DCX CANCELLED",
  "CASP STARTED",
  "CASP COMPLETED",
  "CASP CANCELLED",
  "CASP FAILED",
  "DASP STARTED",
  "DASP COMPLETED",
  "DASP CANCELLED",
  "DASP FAILED",
  "STARTED",
  "CHECKIN",
  "READYFORDELIVERY",
  "READYFORCOLLECTION",
  RepairStatus.AOC_FMIP_LOCKED,
  RepairStatus.DEVICE_RECEIVED,
  RepairStatus.POST_INSPECTION_COMPLETE,
  RepairStatus.POST_INSPECTION_FAILED,
  RepairStatus.PRE_INSPECTION_SUCCESSFUL,
  RepairStatus.POST_INSPECTION,
  RepairStatus.CHECKED_IN,
  RepairStatus.ASP_ASSIGNED,
  RepairStatus.CC_PAYMENT_FAILED,
  RepairStatus.AOC_FMIP_UNLOCKED,
  RepairStatus.RWR_TLC,
  RepairStatus.RWR_ASP,
  RepairStatus.READY_FOR_DISPATCH,
  RepairStatus.PAYMENT_RECEIVED,
  RepairStatus.ACCESSORIES_CONFIRMED,
  RepairStatus.AWAITING_QUALITY_CHECK,
  RepairStatus.QUALITY_CHECK_COMPLETED,
  RepairStatus.REPAIR_ELIGIBILITY_SUCCESSFUL,
  RepairStatus.REPAIR_ELIGIBILITY_FAILED,
  RepairStatus.DISPATCHED_TO_ASP,
  RepairStatus.DISPATCHED_TO_AOC,
  "DEVICE RECEIVED",
  "REPAIR FEASIBLE",
  "REPAIR REJECTED",
  "REPAIR INFEASIBLE",
  "QUOTATION RECEIVED",
  "STARTED",
  "REPAIR COMPLETED",
  "READY FOR DELIVERY",
  "DELIVERY SCHEDULE REQUESTED",
  "DELIVERY SCHEDULED",
  "AWAITING CUSTOMER SELECTION",
  "DEVICE RETURN EXPECTED TO ASURION",
  "PROCEED WITH PROCUREMENT",
  "PROCUREMENT COMPLETED",
  "AWAITING REPLACEMENT CONFIGURATION",
  "RETURN RECEIVED",
  "OVERAGE QUOTATION RECEIVED",
  "PAYMENT RECEIVED",
  "IN PROGRESS",
  'SCHEDULE CONFIRMED',
  'SCHEDULE REJECTED',
  "AWAITING DELIVERY SCHEDULE",
  "READY FOR PROCUREMENT",
  "READY FOR REPAIR",
  "REVIEW QUOTATION",
  "AWAITING CUSTOMER SELECTION",
  "PROCEED WITH REPAIR",
  "AWAITING PAYMENT FROM CUSTOMER",
  "QUOTATION PENDING APPROVAL",
  "AWAITING CLAIM COMPLETION",
  RepairStatus.ESCALATED,
  "RTLC",
  "PICKUP INPROGRESS",
  "PICKUP FAILED",
  "PICKUP RESCHEDULED",
  "DELIVERY FAILED",
  "DELIVERY RESCHEDULED",
  "RETURN FAILED",
  "RETURN RESCHEDULED",
  "AWAITING RETURN SCHEDULE",
  "RETURN SCHEDULE REQUESTED",
  "AWAITING CLAIM CANCELLATION",


];

export const _find = (targetArr, key, keyToMatch) => {
  return targetArr.find((currObj) => currObj[key] === keyToMatch);
};

export const _filter = (targetArr, filterBy, filterOn) => {
  return targetArr.filter((node) => {
    return node[filterBy] !== filterOn;
  });
};

export const _filterMap = (targetArr, filterBy, filterOn) => {
  return _filter(targetArr, filterBy, filterOn).map((node) => node[filterBy]);
};

export const _compact = (target) => {
  if (Array.isArray(target)) {
    return target.filter(Boolean);
  } else if (Object.keys(target).length) {
    let clonedTarget = {};
    Object.keys(
      target.forEach((prop) => {
        if (target[prop]) clonedTarget[prop] = target[prop];
      })
    );
    return clonedTarget;
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const generateUniqueHash = () => {
  const id = uuid();
  const bufferId = new Buffer(id.replace(/-/g, ""), "hex");
  const hash = bufferId.toString("hex").toUpperCase();

  return hash;
};

export const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

export const _groupBy = (arr, property) => {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

export const returnCurrencySymbol = (carrier) => {
  switch (carrier) {
    case "AIS":
    case "Asurion_TechCare":
      return "฿";
    case "Celcom":
    case "MAXIS":
      return "RM";
    default:
      return "$";
  }
};

export const isHTML = (targetStr) => {
  return /<\/?[a-z][\s\S]*>/i.test(targetStr);
};

export const returnTagColor = (repairStatus) => {
  switch (repairStatus.toUpperCase()) {
    case "SCHEDULED":
    case "RESERVED":
    case "READY":
    case "NEW":
    case 'PENDING SCHEDULE CONFIRMATION':
      return "bg-purpleBlue"; // purpleBlue
    case RepairStatus.DEVICE_RECEIVED:
    case RepairStatus.AOC_FMIP_UNLOCKED:
    case RepairStatus.QUALITY_CHECK_COMPLETED:
    case RepairStatus.REPAIR_FEASIABLE:
    case "REPAIR INFEASIBLE":
    case "QUOTATION RECEIVED":
    case "REVIEW QUOTATION":
    case "OVERAGE QUOTATION RECEIVED":
    case "PICKUP INPROGRESS":
    case "SCHEDULE CONFIRMED" :
    case "SCHEDULE REJECTED" :
      return "bg-deepBlue"; // deepBlue
    case RepairStatus.CHECKED_IN:
    case RepairStatus.ACCESSORIES_CONFIRMED:
      return "bg-tennisGreen"; // tennisGreen - black
    case RepairStatus.POST_INSPECTION:
    case RepairStatus.PRE_INSPECTION_SUCCESSFUL:
    case RepairStatus.REPAIR_ELIGIBILITY_SUCCESSFUL:
    case "RETURN RECEIVED":
      return "bg-lightBlue";
    case RepairStatus.ASP_ASSIGNED:
    case RepairStatus.DISPATCHED_TO_AOC:
    case RepairStatus.DISPATCHED_TO_ASP:
      return "bg-darkGreen";
    case "CCX STARTED":
    case RepairStatus.PAYMENT_RECEIVED:
      return "bg-nastyGreen"; // nastyGreen - black
    case "RCX STARTED":
    case "CHECKIN":
      return "bg-lightBlue"; // lightBlue
    case RepairStatus.COMPLETED:
    case RepairStatus.RE_REPAIR_COMPLETED:
    case "CCX COMPLETED":
    case "DCX COMPLETED":
    case "RCX COMPLETED":
    case "DASP COMPLETED":
    case "CASP COMPLETED":
    case "REPAIR COMPLETED":
    case "COMPLETE":
    case "PROCUREMENT COMPLETED":
    case RepairStatus.READY_FOR_DISPATCH:
      return "bg-darkGreen"; // darkGreen
    case "CASP STARTED":
    case "PROCEED WITH PROCUREMENT":
    case RepairStatus.PACKAGING_SHIPMENT:
      return "bg-darkBlue"; // darkBlue

    case RepairStatus.POST_INSPECTION_COMPLETE:
    case "READYFORDELIVERY":
    case "READYFORCOLLECTION":
    case "READY FOR DELIVERY":
    case "DELIVERY SCHEDULE REQUESTED":
    case "DELIVERY SCHEDULED":
    case "AWAITING CUSTOMER SELECTION":
    case "AWAITING CLAIM COMPLETION":
    case "DEVICE RETURN EXPECTED TO ASURION":
    case "AWAITING REPLACEMENT CONFIGURATION":
    case "READY FOR PROCUREMENT":
    case "AWAITING DELIVERY SCHEDULE":
    case "READY FOR REPAIR":
    case RepairStatus.AOC_FMIP_LOCKED:
    case RepairStatus.AWAITING_QUALITY_CHECK:
    case RepairStatus.PICK_UP_INPROGRESS:
    case RepairStatus.PICK_UP_RESCHEDULED:
    case RepairStatus.DELIVERY_RESCHEDULED:
    case RepairStatus.RETURN_FAILED:
    case RepairStatus.RETURN_RESCHEDULED:
    case RepairStatus.AWAITING_RETURN_SCHEDULE:
    case RepairStatus.RETURN_SCHEDULE_REQUESTED:
    case RepairStatus.AWAITING_CLAIM_CANCELLATION:
    case "BORD":
      return "bg-lightOrange"; // lightOrange - black
    case "STARTED":
    case "DCX STARTED":
    case "DASP STARTED":
      return "bg-lightPurple"; // lightPurple
    case "CANCELLED":
    case "DCX FAILED":
    case "RCX FAILED":
    case "DASP FAILED":
    case RepairStatus.CC_PAYMENT_FAILED:
    case "Cancelled by user":
    case "RWR ASP":
    case "RWR TLC":
    case RepairStatus.POST_INSPECTION_FAILED:
    case RepairStatus.REPAIR_ELIGIBILITY_FAILED:
    case RepairStatus.DELIVERY_FAILED:
    case RepairStatus.PICK_UP_FAILED:
    default:
      return "bg-cherryRed"; // cherryRed
  }
};

export const returnTagTextColor = (repairStat) => {
  return [
    "CCX STARTED",
    "READYFORDELIVERY",
    "READYFORCOLLECTION",
    RepairStatus.POST_INSPECTION_COMPLETE,
    RepairStatus.CHECKED_IN,
    RepairStatus.PAYMENT_RECEIVED,
    RepairStatus.ACCESSORIES_CONFIRMED,
    RepairStatus.AWAITING_QUALITY_CHECK,
    RepairStatus.AOC_FMIP_LOCKED,
  ].includes(repairStat)
    ? "text-black"
    : "text-white";
};

export const decideFiltersOnTab = ({
  selectedTab,
  type = null,
  state = {},
}) => {
  let {
    defaultHomePlusFilters,
    defaultPURFilters,
    defaultWalkinFilters,
    defaultMailinFilters,
    defaultAspOnboardingFilters,
    allRecordsDefaultFilters,
    defaultJobsFilters,
    purFilters,
    HomePlusFilters,
    VASFilters,
    walkinFilters,
    mailinFilters,
    aspOnboardingFilters,
    allRecordsFilters,
    jobsFilters,
  } = state;
  if (type === "default") {
    return selectedTab === "PUR"
      ? Object.assign({}, defaultPURFilters)
      : selectedTab === "Home-Plus"
      ? Object.assign({}, defaultHomePlusFilters)
      : selectedTab === "VAS"
      ? Object.assign({}, VASFilters)
      : selectedTab === "Walk-in"
      ? Object.assign({}, defaultWalkinFilters)
      : selectedTab === "Mail-in"
      ? Object.assign({}, defaultMailinFilters)
      : selectedTab === AppFeatures.ASP_ONBRD
      ? Object.assign({}, defaultAspOnboardingFilters)
      : selectedTab === AppFeatures.JOBS
      ? Object.assign({}, defaultJobsFilters)
      : Object.assign({}, allRecordsDefaultFilters);
  }
  return selectedTab === "PUR"
    ? Object.assign({}, purFilters)
    : selectedTab === "Home-Plus"
    ? Object.assign({}, HomePlusFilters)
    : selectedTab === "VAS"
    ? Object.assign({}, VASFilters)
    : selectedTab === "Walk-in"
    ? Object.assign({}, walkinFilters)
    : selectedTab === "Mail-in"
    ? Object.assign({}, mailinFilters)
    : selectedTab === AppFeatures.ASP_ONBRD
    ? Object.assign({}, aspOnboardingFilters)
    : selectedTab === AppFeatures.JOBS
    ? Object.assign({}, jobsFilters)
    : Object.assign({}, allRecordsFilters);
};

export const filterRecordsByStatus = (recordsArr = [], value, selectedTab) => {
  value = value.toUpperCase();
  
  return recordsArr.filter((record) => {
    if (value === "SCHEDULED") {
      if (selectedTab === "Walkin-Claims") {
        
        let filteredRec = scheduledStatus.includes(
          record.repairStat.toUpperCase()
        );
        if (
          (record.repairStat.toUpperCase() === "SHIPP" ||
            record.repairStat.toUpperCase() === "SHIPPED") &&
          record.deliveryDate === "-"
        ) {
          return record;
        } else {
          if (filteredRec) return record;
        }
      } else {
        if (scheduledStatus.includes(record.repairStat.toUpperCase()))
          return record;
      }
    } else if (value === "IN PROGRESS") {
      if (inProgressStatus.includes(record.repairStat.toUpperCase()))
        return record;
    } else if (value === "COMPLETED") {
      if (selectedTab === "Walkin-Claims") {
        let filteredCompClaim =
          !inProgressStatus.includes(record.repairStat.toUpperCase()) &&
          !scheduledStatus.includes(record.repairStat.toUpperCase());
        if (filteredCompClaim) {
          if (
            (record.repairStat.toUpperCase() === "SHIPP" ||
              record.repairStat.toUpperCase() === "SHIPPED") &&
            record.deliveryDate === "-"
          ) {
          } else {
            return record;
          }
        }
      } else {
        if (
          !inProgressStatus.includes(record.repairStat.toUpperCase()) &&
          !scheduledStatus.includes(record.repairStat.toUpperCase())
        )
          return record;
      }
    }
  });
};

export const getStatusWiseCount = (recordsArr, isWalkinClaimsTab) => {
  let scheduled = 0,
    inProgress = 0,
    completed = 0;
  recordsArr &&
    recordsArr.map((record) => {
      if (isWalkinClaimsTab) {
        if (
          
          scheduledStatus.includes(
            record.SWAP_STATUS_CODE
          )
        ) {
          scheduled++;
        } else if (
          inProgressStatus.includes(
           
              record.SWAP_STATUS_CODE &&
                record.SWAP_STATUS_CODE.toUpperCase()
          )
        )
          inProgress++;
        else completed++;
      } else {
        if (
          scheduledStatus.includes(
            record.REPAIR_STATUS_CODE || record.SWAP_STATUS_CODE
          )
        )
          scheduled++;
        else if (
          inProgressStatus.includes(
            (record.REPAIR_STATUS_CODE &&
              record.REPAIR_STATUS_CODE.toUpperCase()) ||
              (record.SWAP_STATUS_CODE &&
                record.SWAP_STATUS_CODE.toUpperCase())
          )
        )
          inProgress++;
        else completed++;
      }
    });
  return {
    scheduled,
    inProgress,
    completed,
  };
};

export const createStandardAddressObj = (addressObj) => {
  return {
    BuildingName: addressObj.BUILDING_NAME || "",
    AddressLine1: addressObj.ADDRESS_LINE_1,
    AddressLine2: addressObj.ADDRESS_LINE_2,
    AddressLine3: addressObj.ADDRESS_LINE_3,
    CityName: addressObj.CITY_NAME,
    StateProvinceCode:
      addressObj.STATE_PROVINCE_CODE || addressObj.STATE_PROVINCE_NAME,
    PostalCode: addressObj.POSTAL_CODE,
    Country:
      addressObj.COUNTRY_NAME === "SGP" ? "Singapore" : addressObj.COUNTRY_NAME,
  };
};

export const getRecordsAccessor = (selectedTab) => {
  return selectedTab === "PUR"
    ? "purRecords"
    : selectedTab === "Home-Plus"
    ? "homePlusRecords"
    :selectedTab === 'VAS'
    ? 'vasRecords'
    : selectedTab === "Walk-in"
    ? "walkinRecords"
    : selectedTab === "Mail-in"
    ? "mailinRecords"
    : selectedTab === "Walkin-Claims"
    ? "WalkinClaimsRecords"
    : selectedTab === AppFeatures.ASP_ONBRD
    ? "aspRecords"
    : selectedTab === AppFeatures.JOBS
    ? "jobsRecords"
    : "allRecords";
};

export const oneDayTime = (selectedDate) => {
  if (selectedDate) {
    let startDate = moment
      .utc(selectedDate.startOf("day"))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment
      .utc(selectedDate.endOf("day"))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    return { startDate, endDate };
  } else {
    let startDate = moment
      .utc(moment().startOf("day"))
      .format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment
      .utc(moment().endOf("day"))
      .format("YYYY-MM-DD HH:mm:ss");
    return { startDate, endDate };
  }
};

export const getFormattedDateTime = (datetime, dateformat) => {
  var zone = moment.tz.guess();
  return moment.tz(datetime, zone).format(dateformat);
};

export const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  if (typeof input === "string" && input.trim().length === 0) {
    return true;
  }
  return !input || Object.keys(input).length === 0;
};

export const convertToCamelCase = (text) => {
  return text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const compareObject = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sanitize = (value) => {
  if (isSecureString(value)) {
    return DOMPurify.sanitize(value);
  } else {
    return undefined;
  }
};

export const isSecureString = (value) => {
  let isSecure = true;
  const startingPosChar = ["=", "+", "-", "@", "\t", "\n"];
  const containsChat = ["'", '"', ",", ";"];
  startingPosChar.forEach((c) => {
    if (value.startsWith(c)) {
      isSecure = false;
    }
  });
  if (isSecure) {
    containsChat.forEach((c) => {
      if (value.includes(c)) {
        isSecure = false;
      }
    });
  }
  return isSecure;
};

export const formatAString = (text) => {
  return text
    .replaceAll(String.fromCharCode(45), "")
    .replaceAll(String.fromCharCode(119), String.fromCharCode(97))
    .replaceAll(String.fromCharCode(113), String.fromCharCode(101))
    .replaceAll(String.fromCharCode(112), String.fromCharCode(50));
};
function escapeQuotes(name) {
  //return name.replace(/'/g, "''");
  name = name.replace(/[\[\]]/g, "\\$&");
  return name;
}

export const getParameterByName = (name, url = window.location.href) => {
  //name = name.replace(/[\[\]]/g, "\\$&");
  escapeQuotes(name);
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export function getChangedTaskTags(taskTags, deliveryWindow) {
  // Change 'Malfunction, 4H, 400018656' 4H with current deliveryWindow
  if (!taskTags) return "";
  if (deliveryWindow) {
    const taskTagArr = taskTags.split(", ");
    taskTagArr[1] = deliveryWindow;
    return taskTagArr.join(", ");
  }
  return taskTags;
}

export function getAddressPostalCode({ NewAddress, OldAddress }) {
  try {
    // console.log("getAddressPostalCode inputs :: ", NewAddress, OldAddress);
    const addrStr = NewAddress
      ? NewAddress.split(" | ")
      : OldAddress.split(" | ");
    // console.log(
    //   "getAddressPostalCode new :: ",
    //   addrStr,
    //   addrStr.length,
    //   addrStr[addrStr.length - 1]
    // );
    // console.log("getAddressPostalCode old address: ", OldAddress);
    return addrStr[addrStr.length - 1];
  } catch (e) {
    return "";
  }
}

export function getDateFormatted(date) {
  return getFormattedDateTime(date, "YYYY-MM-DD hh:mm A");
}

export function deepCopyFunction(inObject) {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
}

export function convertToDecimal(val) {
  let str;
  if (val) {
    str = val;
    if (typeof str === "number") {
      if (str % 1 === 0) {
        str = str.toFixed(2);
      } else {
        str = str;
      }
    } else {
      if (str.indexOf(".") === -1) {
        str = str + ".00";
      } else {
        str = str;
      }
    }
  } else {
    str = "0.00";
  }
  return str;
}

export const filterFunction = (arr, key) => {
  const uniqueElementArr = [...new Set(arr.map((item) => item[key]))].filter(
    Boolean
  );

  const uniqueElementFilter = uniqueElementArr.reduce(
    (acc, value) => acc.concat({ text: value, value: value }),
    []
  );
  return uniqueElementFilter;
};
